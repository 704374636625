import React from "react"
import {
  Button,
  Image,
  HStack,
  Link,
  Skeleton,
  Heading,
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useDisclosure,
} from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"

import countries from "../../data/countries-iso-3166-1-alpha-2.json"
import AppleAppStoreBadgeEnSvg from "../../assets/images/apple-app-store-badge.svg"
import GooglePlayStoreBadgeEnSvg from "../../assets/images/google-play-store-badge.svg"

const AppStoreButtons = ({
  loading = false,
  available = true,
  chakraStyleProps,
  naChakraStyleProps,
}) => {
  return loading ? (
    <AppStoreButtonSkeletons chakraStyleProps={chakraStyleProps} />
  ) : available ? (
    <HStack {...chakraStyleProps}>
      <Link
        href="https://play.google.com/store/apps/details?id=com.yara.connect.prod"
        isExternal
      >
        <Image height="12" src={GooglePlayStoreBadgeEnSvg} />
      </Link>
      <Link
        href="https://apps.apple.com/th/app/yaraconnect/id1531837503"
        isExternal
      >
        <Image height="12" src={AppleAppStoreBadgeEnSvg} />
      </Link>
    </HStack>
  ) : (
    <NotAvailable naChakraStyleProps={naChakraStyleProps} />
  )
}

const AppStoreButtonSkeletons = ({ chakraStyleProps }) => {
  return (
    <HStack {...chakraStyleProps}>
      <Skeleton width="36" height="12" />
      <Skeleton width="36" height="12" />
    </HStack>
  )
}

const NotAvailable = ({ naChakraStyleProps }) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <VStack {...naChakraStyleProps}>
        <Heading
          fontSize={{ base: "1.2rem", sm: "1.5rem" }}
          color="inherit"
          fontWeight="400"
        >
          {t(`components.app-store-buttons.not-available-yet`)}
        </Heading>
        <Text
          color="white"
          cursor="pointer"
          userSelect="none"
          px={{ base: 4, sm: 6 }}
          py={{ base: 2, sm: 4 }}
          fontSize={{ base: "1rem", sm: "1.2rem" }}
          textAlign="center"
          fontWeight="600"
          borderRadius="0.45rem"
          transition="all 0.2s"
          backgroundColor="ahuaBlue.80"
          _hover={{ backgroundColor: "ahuaBlue.90" }}
          _active={{ backgroundColor: "ahuaBlue.100" }}
          onClick={onOpen}
        >
          {t(`components.app-store-buttons.let-us-know-you-want`)}
        </Text>
      </VStack>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent backgroundColor="ahuaBlue.90" color="white">
          <ModalHeader textAlign="center">I want YaraConnect</ModalHeader>
          <ModalCloseButton />
          <ModalBody my="4">
            <Text mb="4">
              {t(
                `components.app-store-buttons.fill-country-modal.country-select-label`
              )}
            </Text>
            <Select
              color="black"
              placeholder="Select country"
              size="lg"
              backgroundColor="white"
            >
              {countries.map((country, index) => (
                <option
                  key={`select-option-country-${index + 1}`}
                  value={country}
                >
                  {country}
                </option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              {t(
                `components.app-store-buttons.fill-country-modal.submit-button`
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default AppStoreButtons
